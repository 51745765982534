//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import { bulkCreateGeneticAnalystService } from "@debionetwork/polkadot-provider";
import { pencilIcon, trashIcon } from "@debionetwork/ui-icons";
import AddServiceForm from "@/common/components/Service/AddService";
import DeleteDialog from "@/common/components/Dialog/DeleteServiceDialog";
import { toEther, formatUSDTE } from "@/common/lib/balance-format.js";
import { uploadFile, getFileUrl } from "@/common/lib/pinata-proxy";
const stepData = [{
  label: "Set Up Account",
  active: false
}, {
  label: "Set Up Service",
  active: true
}];
export default {
  name: "GARegistration",
  components: {
    AddServiceForm,
    DeleteDialog
  },
  data: () => ({
    pencilIcon,
    trashIcon,
    stepData: stepData,
    services: [],
    service: {
      name: "",
      currency: "",
      totalPrice: "",
      duration: "",
      durationType: "Days",
      description: "",
      testResultSample: null,
      file: {
        name: ""
      }
    },
    submitLoading: false,
    servicesLoading: false,
    modalDelete: null,
    editId: null
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3,
      wallet: state => state.substrate.wallet
    }),
    ...mapGetters({
      pair: "substrate/wallet"
    })
  },
  methods: {
    async onSubmitService(value) {
      const {
        currency,
        description,
        duration,
        durationType,
        file,
        name,
        testResultSample,
        totalPrice,
        additionalPrice
      } = value;
      const descriptionLink = await this.DescriptionLink(description, name);
      const dataToSend = {
        name,
        pricesByCurrency: [{
          currency,
          totalPrice
        }],
        expectedDuration: {
          duration,
          durationType
        },
        description: descriptionLink,
        testResultSample
      };
      const services = this.services;
      if (this.editId !== null) {
        services[this.editId] = {
          ...dataToSend,
          file: {
            name: file.name
          }
        };
        this.editId = null;
      } else {
        services.push({
          ...dataToSend,
          file: {
            name: file.name
          },
          additionalPrice
        });
      }
      this.clearForm();
    },
    editService(id) {
      this.editId = id;
      const data = this.services[id];
      const service = {
        name: data.name,
        currency: data.currency,
        totalPrice: data.pricesByCurrency[0].totalPrice,
        duration: data.expectedDuration.duration,
        durationType: "Days",
        description: data.description,
        testResultSample: data.testResultSample,
        file: data.file,
        additionalPrice: data.additionalPrice
      };
      this.service = service;
      const element = this.$refs["title"];
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
    cancelEdit() {
      this.clearForm();
      this.editId = null;
    },
    deleteService() {
      const services = this.services;
      services.splice(this.modalDelete, 1);
      this.services = services;
      this.modalDelete = null;
    },
    async DescriptionLink(description, name) {
      let blob = new Blob([description], {
        type: "text/plain"
      });
      const result = await uploadFile({
        title: "".concat(name, "DescriptionFile.txt"),
        type: "txt",
        size: blob.size,
        file: blob
      });
      const link = getFileUrl(result.IpfsHash);
      return link;
    },
    async onSubmit() {
      const services = [];
      const data = this.services;
      this.submitLoading = true;
      data.forEach(service => {
        const totalPrice = toEther(Number(service.pricesByCurrency[0].totalPrice) + Number(service.additionalPrice), service.pricesByCurrency[0].currency);
        const servicePrice = toEther(service.pricesByCurrency[0].totalPrice, service.pricesByCurrency[0].currency);
        const qcPrice = toEther(service.additionalPrice, service.pricesByCurrency[0].currency);
        delete service.file;
        delete service.additionalPrice;
        services.push({
          ...service,
          pricesByCurrency: [{
            currency: formatUSDTE(service.pricesByCurrency[0].currency),
            totalPrice: totalPrice,
            priceComponents: [{
              component: "Main Price",
              value: servicePrice
            }],
            additionalPrices: [{
              component: "QC Price",
              value: qcPrice
            }]
          }]
        });
      });
      try {
        await bulkCreateGeneticAnalystService(this.api, this.wallet, services);
        this.$router.push({
          name: "ga-services"
        });
      } catch (error) {
        console.error(error);
      }
      this.submitLoading = false;
    },
    clearForm() {
      this.service = {
        name: "",
        currency: "",
        totalPrice: "",
        duration: "",
        durationType: "Days",
        description: "",
        testResultSample: null,
        file: {
          name: ""
        }
      };
    },
    limitChar(value, limit) {
      return value.length > limit ? "".concat(value.substring(0, limit), "...") : value;
    }
  }
};